// services
import Glient from "./glient";
import Constants from "./constants";
import { StorageKeys, Storage } from "./storage";
// types
import type { ReadonlyDeep } from "type-fest";
import type { CmdGetTable, LoginTableVersions } from "../types/message";
import type { TableName, Tables, TableCallback, RocIdCallback, RulesCallback, AdvancedRulesCallback, QuickViewConfigCallback, RocId, RocIdEntry, RocIdData, TemplateRulesParam } from "../types/roc-table";
import type { ClusterId } from "../types/cluster";

/**
 * An storage for all the user tables such as rocid_dict, grules etc.
 * The table data is stored in localstorage of the browser.
 * Everytime a request is made to fetch a table, firstly it is checked
 * in the localstorage with the version stored in storage against the
 * latest version in user login response. If the stored version is older,
 * the requested table is made to the server.
 *
 * @class RocTable
 */
class RocTable {

	#tableVersions: LoginTableVersions = {
		api_schema: -1,
		api_schemas: -1,
		border_patrol: -1,
		channels: -1,
		device_config: -1,
		grules: -1,
		grules_advanced: -1,
		quickview_config: -1,
		report_dict: -1,
		rocid_dict: -1,
		table_schemas: -1,
		zigbee_config: -1,
	};

	#tables: Tables = {
		rocid_dict: this.#getStoredTable(Constants.TableName.RocIdDict),
		grules: this.#getStoredTable(Constants.TableName.Grules),
		grules_advanced: this.#getStoredTable(Constants.TableName.GrulesAdvanced),
		quickview_config: this.#getStoredTable(Constants.TableName.QuickViewConfig),
	};

	#getStoredTable<T extends TableName>(tableName: T): Tables[T] {
		return Storage.get(StorageKeys.rocTable, {TABLE_NAME: tableName}) as Tables[T];
	}

	#getStoredTableVersion(tableName: TableName): number {
		return Storage.get(StorageKeys.rocTableVersion, {TABLE_NAME: tableName});
	}

	public setTableVersions(tableVersions: LoginTableVersions): void {
		this.#tableVersions = tableVersions;
	}

	public isTableLoadedAndSynced(tableName: TableName): boolean {
		return this.#tables[tableName].length > 0 && this.#tableVersions[tableName] === this.#getStoredTableVersion(tableName);
	}

	/**
	 * Fetches the rocid_dict table.
	 * @param {Function} callback
	 */
	public getRocIdTable(callback: RocIdCallback): void {
		this.#getTable(Constants.TableName.RocIdDict, callback);
	}

	/**
	 * Fetches the quickview_config table.
	 * @param {Function} callback
	 */
	public getQuickViewConfigTable(callback: QuickViewConfigCallback): void {
		this.#getTable(Constants.TableName.QuickViewConfig, callback);
	}

	/**
	 * Fetches the grules table
	 * @param {Function} callback
	 */
	public getGrRules(callback: RulesCallback): void {
		this.#getTable(Constants.TableName.Grules, callback);
	}

	/**
	 * Fetches the grules_advanced table
	 * @param {Function} callback
	 */
	public getAdvancedRules(callback: AdvancedRulesCallback): void {
		this.#getTable(Constants.TableName.GrulesAdvanced, callback);
	}

	/**
	 * Fetches the table from server after checking the stored version.
	 * @param {String}   tableName
	 * @param {Function} callback
	 */
	#getTable<T extends TableName>(tableName: T, callback: TableCallback<T>): void {
		const storedVersion = this.#getStoredTableVersion(tableName);
		const newestVersion = this.#tableVersions[tableName];

		if (this.#tables[tableName].length > 0 && storedVersion > -1 && storedVersion === newestVersion) {
			callback(this.#tables[tableName]);
		} else {
			this.#fetchTable(tableName, (table) => {
				this.#tables[tableName] = table;
				Storage.set(StorageKeys.rocTable, table, {TABLE_NAME: tableName});
				Storage.set(StorageKeys.rocTableVersion, newestVersion, {TABLE_NAME: tableName});
				callback(table);
			});
		}
	}

	#fetchTable<T extends TableName>(tableName: T, callback: TableCallback<T>): void {
		const cmd = {
			action: "getTable",
			tableName: tableName,
		} as const satisfies CmdGetTable;
		Glient.send(cmd, (error, msg) => {
			const table = error ? [] : msg.payload.data as Tables[T];
			callback(table);
		});
	}

	public getRocIdData(rocId: RocId): RocIdData {
		if (!this.isTableLoadedAndSynced(Constants.TableName.RocIdDict)) {
			console.error("RocIdDict not loaded/synced!");
		}

		const rocIdTable = this.#tables[Constants.TableName.RocIdDict];
		if (rocIdTable.length === 0) {
			throw new Error("RocIdDict table is not loaded or empty!");
		}

		let rocIdEntry = rocIdTable.find((_rocIdEntry) => (_rocIdEntry.id === rocId));
		if (rocIdEntry === undefined) {
			console.warn("Missing rocId", rocId);
			rocIdEntry = rocIdTable.find((_rocIdEntry) => (_rocIdEntry.id === Constants.RocIdUnderConstruction));
		}

		if (rocIdEntry === undefined) {
			throw new Error("RocIdDict table has no FFFF entry!");
		}

		return (rocIdEntry as RocIdEntry).data;
	}

	/**
	 * Checks for clusterId in the rule param and finds in param config
	 * @param {Object}   rulesParam
	 * @param {Object[]} params
	 * @return {String|undefined}
	 */
	public getClusterId(rulesParam: ReadonlyDeep<TemplateRulesParam>, params: ReadonlyDeep<Array<TemplateRulesParam>>): ClusterId | undefined {
		if (rulesParam.ruleJson.clusterId) {
			return rulesParam.ruleJson.clusterId;
		}
		const param = params.find((param) => (param.config === rulesParam.id));
		return param?.ruleJson.clusterId;
	}

}

export default (new RocTable());
